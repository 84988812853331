<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="posturl"
      :posturl2="posturl2"
      :title="title"
      :custom_save="true"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      title:'Ajukan Perubahan Data',
      posturl:'/vendor',
      posturl2:'/vendor/'+this.$route.params.id+'/revision',
      fields :[
        { key:'photo', label:'Logo Vendor', placeholder:'Logo Vendor', type: 'image', rules:'' },
        { key:'region', label:'Akses Region', type: 'select2',rules:'required',dataurl:'/region'},
        { key:'code', label:'Kode', placeholder:'Kode Vendor', type: 'input',rules:'required' },
        { key:'name', label:'Nama', placeholder:'Nama Vendor', type: 'input',rules:'required' },
        { key:'address', label:'Alamat', placeholder:'Alamat',  type: 'input',rules:'required' },
        { type:'map', label:''},
        { key:'city', label:'Kota', type: 'select2',rules:'',dataurl:'/city' },
        { key:'postal_code', label:'Kode pos', placeholder:'Kode Pos',  type: 'input',rules:'' },
        { key:'email', label:'Email', placeholder:'Email',  type: 'input',rules:'required|email' },
        { key:'vendor_type', label:'Tipe', type: 'select2',rules:'',dataurl:'/vendor-type' },
        { key:'phone', label:'Telephone', placeholder:'Telephone',  type: 'input',rules:'' },
        { key:'hr'},
        { key:'cp_name', label:'Contact Person', placeholder:'Nama Contact Person', type: 'input',rules:'required' },
        { key:'cp_number', label:'Telephone Contact Person', placeholder:'Telephone', type: 'input',rules:'' },
        { key:'cp_email', label:'Email Contact Person', placeholder:'Email', type: 'input',rules:'' },
        { key:'hr'},
        { key:'date', label:'Tanggal Register',  type: 'date',rules:'', val:'now' },
        { key:'hr'},
        { key:'section', desc: 'Data Bank'},
        { key:'account_bank', label:'Nama Bank', placeholder:'Nama Bank', type: 'input',rules:'required' },
        { key:'account_owner', label:'Nama Penerima', placeholder:'Nama Penerima', type: 'input',rules:'required' },
        { key:'account_number', label:'No Rekening', placeholder:'No Rekening', type: 'input',rules:'required' },
        { key:'hr'},
        { key:'section', desc: 'Section Direksi'},
        { key:'director_name', label:'Nama', placeholder:'Nama Direksi', type: 'input',rules:'required' },
        { key:'director_jobtitle', label:'Jabatan', placeholder:'Jabatan Direksi', type: 'input',rules:'required' },
        { key:'hr'},
        { key:'section', desc: 'Section Keuangan'},
        { key:'accountant_name', label:'Nama', placeholder:'Nama Tertuju', type: 'input',rules:'required' },
        { key:'accountant_jobtitle', label:'Jabatan', placeholder:'Jabatan Tertuju', type: 'input',rules:'required' },
        { key:'hr'},
        { type: 'p', title:'Akun Aplikasi Vendor Channel', value:'User name yang digunakan adalah email yang dimasukkan' },
        { key:'create_user', label: '', type: 'bool', title:'Daftarkan Akses aplikasi' },
        { key:'password', label:'Password', placeholder:'Input Password', type: 'password',rules:'' ,disabled:true },
        { key:'retype_password', label:'Ulang Password', placeholder:'Ulangi Password', type: 'password',rules:'',disabled:true },
      ],
    }
  },
}
</script>